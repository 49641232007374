import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const home = () => import('@/home/index.vue')

export const routes = [{
    path: '/', component: home, children: [
      {
         path: '', component: home, name: 'index',
      },
    ]
},
{
    path: '*',
    redirect: '/'
}]

const router = new VueRouter({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            var warper = document.getElementById("app-warper");
            warper.scroll(0, 0)
            return { x: 0, y: 0 } // return的结果, 就是 期望滚动到哪个的位置
        }
     },
    routes,
})


export default router